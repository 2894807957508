import {datadogLogs} from "@datadog/browser-logs";
import {QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {
  Body,
  Heading,
  LayoutErrorBoundary,
  PageHeaderContainer,
  Root,
  SiteLogo,
  ThemeProvider,
} from "@title-service/ui";
import React, {useCallback} from "react";

import {Dependencies, DependencyContext} from "./DependencyContext";
import {CplVerificationPage} from "./pages/CplVerification";

const PageHeaderRight: React.FC = () => (
  <Heading
    level={5}
    color="font.secondary"
    fontWeight="normal"
    value="A MunichRe company"
  />
);

const Layout: React.FC = ({children}) => {
  const handleError = useCallback(
    (error: Error, errorInfo: React.ErrorInfo) => {
      datadogLogs.logger.error(error.message, errorInfo);
    },
    [],
  );
  const pageHeaderAlignItems: React.ComponentProps<
    typeof PageHeaderContainer
  >["alignItems"] = "flex-end";
  const pageHeaderRight = <PageHeaderRight />;

  return (
    <LayoutErrorBoundary
      pageHeaderAlignItems={pageHeaderAlignItems}
      pageHeaderRight={pageHeaderRight}
      onError={handleError}
    >
      <Root>
        <PageHeaderContainer alignItems={pageHeaderAlignItems}>
          <SiteLogo />
          {pageHeaderRight}
        </PageHeaderContainer>
        <Body>{children}</Body>
      </Root>
    </LayoutErrorBoundary>
  );
};

export const App: React.FC<{dependencies: Dependencies}> = ({dependencies}) => (
  <QueryClientProvider client={dependencies.queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <DependencyContext.Provider value={dependencies}>
      <ThemeProvider>
        <Layout>
          <CplVerificationPage />
        </Layout>
      </ThemeProvider>
    </DependencyContext.Provider>
  </QueryClientProvider>
);
