import * as Yup from "yup";

import {CPL_STATUS_SCHEMA, CplStatus} from "./CplStatus";

export type VerifyCplResponse = {
  cplStatus: CplStatus;
};

export const VERIFY_CPL_RESPONSE_SCHEMA: Yup.ObjectSchema<VerifyCplResponse> =
  Yup.object({
    cplStatus: CPL_STATUS_SCHEMA.required(),
  });
