import {Alert} from "@aws-amplify/ui-react";
import {useMutation} from "@tanstack/react-query";
import {
  CplStatus,
  VerifyCplRequest,
  VerifyCplResponse,
} from "@title-service/cpl-verification-api-client";
import {
  BodyContent,
  BodyHeader,
  FormikConnectedAmplifySubmitButton,
  FormikConnectedAmplifyTextField,
  FormikForm,
  FormResultErrorMessage,
  FormResultSuccessMessage,
  PrimaryBodyHeader,
  PrimaryBodyHeaderContainer,
  PrimarySection,
} from "@title-service/ui";
import {notNullOrUndefined} from "@title-service/utils";
import {Formik, FormikHelpers} from "formik";
import React, {useCallback, useContext, useMemo} from "react";
import * as Yup from "yup";

import {DependencyContext} from "../DependencyContext";

export const FORM_SCHEMA: Yup.ObjectSchema<VerifyCplRequest> = Yup.object({
  cplId: Yup.string().required("Please enter the ID found on the CPL.").trim(),
});

const buildInitialFormData = (): VerifyCplRequest => ({cplId: ""});

export const CplVerificationPage: React.FC = () => {
  const {cplVerificationApi} = useContext(DependencyContext);
  const verifyCplMutation = useMutation({
    mutationFn: cplVerificationApi.verifyCpl,
  });
  const handleSubmit = useCallback(
    (
      formData: VerifyCplRequest,
      {setSubmitting}: FormikHelpers<VerifyCplRequest>,
    ) => {
      const verifyCplRequest = FORM_SCHEMA.validateSync(formData, {
        stripUnknown: true,
      });
      verifyCplMutation.mutate(verifyCplRequest, {
        onSettled: () => {
          setSubmitting(false);
        },
      });
    },
    [verifyCplMutation],
  );
  const initialFormData = useMemo(() => buildInitialFormData(), []);

  return (
    <>
      <BodyHeader>
        <PrimaryBodyHeaderContainer>
          <PrimaryBodyHeader value="CPL Verification" />
        </PrimaryBodyHeaderContainer>
      </BodyHeader>
      <BodyContent>
        <PrimarySection>
          <Formik<VerifyCplRequest>
            initialValues={initialFormData}
            validationSchema={FORM_SCHEMA as any}
            onSubmit={handleSubmit}
          >
            <FormikForm>
              <FormikConnectedAmplifyTextField
                testId="cpl-id-input"
                label="CPL ID"
                name="cplId"
                width="400px"
              />

              <FormikConnectedAmplifySubmitButton testId="submit-button" />

              {verifyCplMutation.isSuccess ? (
                <CplVerificationSuccessResponseComponent
                  testId="result-success"
                  response={verifyCplMutation.data}
                />
              ) : null}

              {verifyCplMutation.isError ? (
                <FormResultErrorMessage testId="result-error">
                  Request to verify CPL failed due to a technical error
                </FormResultErrorMessage>
              ) : null}
            </FormikForm>
          </Formik>
        </PrimarySection>
      </BodyContent>
    </>
  );
};

export const CplVerificationSuccessResponseComponent: React.FC<
  Omit<React.ComponentProps<typeof Alert>, "children"> & {
    children?: undefined;
    response: VerifyCplResponse | null;
  }
> = ({response, ...props}) => {
  if (notNullOrUndefined(response)) {
    if (response.cplStatus === CplStatus.VALID) {
      return (
        <FormResultSuccessMessage {...props}>
          CPL is valid
        </FormResultSuccessMessage>
      );
    }
    return (
      <FormResultSuccessMessage {...props}>
        CPL exists but has been voided by the Agent
      </FormResultSuccessMessage>
    );
  }
  return (
    <FormResultErrorMessage {...props}>
      CPL could not be found
    </FormResultErrorMessage>
  );
};
