import "@title-service/ui/src/styles.css";
import "@aws-amplify/ui-react/styles.css";
import "./shared/util/initGlobal";
import {datadogLogs} from "@datadog/browser-logs";
import {QueryClient} from "@tanstack/react-query";
import {HttpCplVerificationApi} from "@title-service/cpl-verification-api-client";
import React from "react";
import ReactDOM from "react-dom";

import {App} from "./App";
import {Dependencies} from "./DependencyContext";

type ApplicationConfig = {
  appElementId: string;
  cplVerificationApi: {
    baseUrl: string;
    adticBaseUrl: string;
  };
  datadog: {
    app: string;
    clientToken: string;
    env: string;
    family: string;
    service: string;
    version: string;
  };
};

export const main = (config: ApplicationConfig) => {
  datadogLogs.init({
    clientToken: config.datadog.clientToken,
    env: config.datadog.env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: "all",
    forwardReports: "all",
    sampleRate: 100,
    service: config.datadog.service,
    site: "datadoghq.com",
    version: config.datadog.version,
  });
  datadogLogs.addLoggerGlobalContext("family", config.datadog.family);
  datadogLogs.addLoggerGlobalContext("app", config.datadog.app);
  datadogLogs.logger.setLevel("warn");

  const {hostname} = window.location;
  const cplVerificationApiBaseUrl = hostname.includes("mrdigitalpartners.com")
    ? config.cplVerificationApi.baseUrl
    : config.cplVerificationApi.adticBaseUrl;

  const dependencies: Dependencies = {
    cplVerificationApi: new HttpCplVerificationApi(cplVerificationApiBaseUrl),
    queryClient: new QueryClient({
      defaultOptions: {
        queries: {
          networkMode: "always",
        },
        mutations: {
          networkMode: "always",
        },
      },
    }),
  };

  ReactDOM.render(
    <App dependencies={dependencies} />,
    document.getElementById(config.appElementId),
  );
};
