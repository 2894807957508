import {
  CplVerificationApi,
  CplVerificationApiError,
} from "./CplVerificationApi";
import {VerifyCplRequest, VerifyCplResponse} from "./models";
import {VERIFY_CPL_RESPONSE_SCHEMA} from "./models/VerifyCplResponse";

export class HttpCplVerificationApi implements CplVerificationApi {
  constructor(private readonly baseUrl: string) {}

  verifyCpl = async (
    {cplId}: VerifyCplRequest,
    signal?: AbortSignal,
  ): Promise<VerifyCplResponse | null> => {
    const requestUrl = new URL(this.baseUrl);
    requestUrl.pathname = `/v1/verify/${cplId}`;
    const response = await fetch(requestUrl.toString(), {
      method: "GET",
      mode: "cors",
      signal,
    });
    if (response.status === 200) {
      const responseBody = await response.json();
      return VERIFY_CPL_RESPONSE_SCHEMA.validateSync(responseBody);
    } else if (response.status === 404) {
      return null;
    }
    throw new CplVerificationApiError(response);
  };
}
