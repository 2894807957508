import {QueryClient} from "@tanstack/react-query";
import {CplVerificationApi} from "@title-service/cpl-verification-api-client";
import React from "react";

export type Dependencies = {
  queryClient: QueryClient;
  cplVerificationApi: CplVerificationApi;
};

export const DependencyContext: React.Context<Dependencies> =
  React.createContext(undefined as any);
