import {VerifyCplRequest, VerifyCplResponse} from "./models";

export class CplVerificationApiError extends Error {
  constructor(readonly response: Response) {
    super(`Unexpected response: ${response.status}`);
    this.name = CplVerificationApiError.name;
  }
}

export interface CplVerificationApi {
  verifyCpl: (
    request: VerifyCplRequest,
    signal?: AbortSignal,
  ) => Promise<VerifyCplResponse | null>;
}
